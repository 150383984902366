<template>
  <section>
    <b-tabs content-class="mt-2">
      <b-tab :title="$t('LICENSE.TITLE')">

        <h5 class="mt-3">{{ $t("DESCRIPTION") }}</h5>
        <div class="mt-1">
          <b-form-input trim
            v-model="prices.description"
            class="w-150"
          ></b-form-input>
        </div>

        
        <h5 class="mt-3">{{ $t("LICENSE.CURRENCY") }}</h5>
        <div class="mt-1">
          <b-form-input trim v-model="prices.currency" class="w-150"></b-form-input>
        </div>

        
        <h5 class="mt-3">{{ $t("LICENSE.TITLE") }}</h5>
        <div class="mt-1">

          <b-form-input trim
            v-model="prices.license"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("PER_MONTH") }}
        </div>

        
        <h5 class="mt-3">{{ $t("LICENSE.INCLUDED_ACCOUNTS") }}</h5>
        <div class="mt-1">
          <b-form-input trim
            v-model="prices.included_accounts"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ $t("EACH") }}
        </div>

        
        <h5 class="mt-3">{{ $t("LICENSE.ACCOUNTS") }}</h5>
        <div class="mt-1">
          <b-form-input trim
            v-model="prices.accounts"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH_PER_MONTH") }}
        </div>
      </b-tab>
      <b-tab :title="$t('BILLING.AUTHENTICATIONS')">

        
        <h5 class="mt-3">{{ $t("AUTH_METHODS.freja") }}</h5>
        <div class="mt-1">
          <b-form-input trim
            v-model="prices.login_freja"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH_UNIQUE") }}
        </div>


        
        <h5 class="mt-3">{{ $t("AUTH_METHODS.freja-org") }}</h5>
        <div class="mt-1">

          <b-form-input trim
            v-model="prices.login_freja_org"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH_UNIQUE") }}
        </div>

        
        <h5 class="mt-3">{{ $t("AUTH_METHODS.bankid-se") }}</h5>
        <div class="mt-1">
            <b-form-input trim
              v-model="prices.login_bankid_se"
              type="number"
              class="w-150"
              :number="return_number"
            ></b-form-input>
            {{ prices.currency }} {{ $t("EACH") }}
        </div>
      </b-tab>

      <b-tab :title="$t('BILLING.VERIFICATIONS')">
        
        <h5 class="mt-3">{{ $t("AUTH_METHODS.freja") }}</h5>
        <div class="mt-1">
          <b-form-input trim
            v-model="prices.verify_freja"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH_UNIQUE") }}
        </div>


          <h5 class="mt-3">{{ $t("AUTH_METHODS.bankid-se") }}</h5>
          <div class="mt-1">
          <b-form-input trim
            v-model="prices.verify_bankid_se"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH") }}
        </div>
        
        <h5 class="mt-3">{{ $t("AUTH_METHODS.sms") }}</h5>
        <div class="mt-1">

          <b-form-input trim
            v-model="prices.verify_sms"
            type="number"
            class="w-150"
            :number="return_number"
          ></b-form-input>
          {{ prices.currency }} {{ $t("EACH") }}
      </div>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
export default {
  props: ["prices", "partner"],
  data() {
    return {
      return_number: true
    };
  },
  methods: {
  },
  computed: {
  },
  mounted(){    
  }
};
</script>
<style></style>
