<template>
  <span>
    <span v-b-toggle.administration-add-node>
      <slot></slot>
    </span>

    <b-sidebar
      sidebar-class="big_sidebar"
      id="administration-add-node"
      v-model="show"
      backdrop
      lazy
      @shown="showSidebar"
      right
      no-slide
      shadow
    >
      <div class="px-3 py-2 create-message" v-if="license">
        <b-overlay :show="loading" rounded="sm">

          <b-alert show class="small-text">
            {{ $t("NOTE.ONLY_AS_NODE") }}
            <br />
            {{ $t("NOTE.ORGANISATION_AS_SERVICE") }}
            <router-link :to="{ name: 'Administration.Organisations' }">{{
              $t("CLICK_HERE")
            }}</router-link>
          </b-alert>

          <div v-if="step == 1">
            
            
            <h5 class="mt-3">{{ $t("ORGANISATION.TITLE") }}</h5>
            <div class="mt-1">
            
            <b-form-select name="add_node_select_organisation" v-model="payload.organisation_id">
              <template v-slot:first>
                <b-form-select-option value="0">{{
                  $t("NEW")
                }}</b-form-select-option>
              </template>
              <b-form-select-option
                v-for="organisation in organisations"
                :key="organisation.id"
                :value="organisation.id"
                >{{ organisation.name }}</b-form-select-option
              >
            </b-form-select>
            </div>

            <div v-show="payload.organisation_id == '0'">
              
            
            <h5 class="mt-3">{{ $t("NAME") }}</h5>
            <div class="mt-1">            
                <b-form-input trim
                  type="text"
                  v-model="payload.organisation.name"
                  name="payload.organisation.name"
                ></b-form-input>
            </div>       

            
            
            <h5 class="mt-3">{{ $t("LANGUAGE.TITLE") }}</h5>
            <div class="mt-1">
                <b-form-select name="add_node_select_language" v-model="payload.organisation.language">
                  <b-form-select-option
                    v-for="(value, key) in sefos_locales"
                    :key="key"
                    :value="key"
                  >
                    {{ $t("LANGUAGE." + key) }}
                  </b-form-select-option>
                </b-form-select>
            </div>
              
            
            
            <h5 class="mt-3">{{ $t("ORGANISATION.UNIQUE_IDENTIFIER") }}</h5>
            <div class="mt-1">
            
                <b-form-input trim
                  type="text"
                  v-model="payload.organisation.unique_identifier"
                  name="payload.organisation.unique_identifier"
                  :state="organisationRegistered"
                ></b-form-input>

                <b-form-invalid-feedback
                  id="input-unique-identifier-feedback"
                >
                  {{ $t("ERROR.ALREADY_REGISTERED") }}
                </b-form-invalid-feedback>
            </div>

            </div>

            <hr class="p-0 m-0 mt-4 mb-2" />

            <b-button
              variant="primary"
              class="mt-2 btn-fill"
              :disabled="disabledStep2"
              @click="gotoStep2"
              >{{ $t("NEXT") }}</b-button
            >

          </div>
          <div v-if="step == 2">

            
            <h5 class="mt-3">SEFOS {{ $t("NAME") }}</h5>
            <div class="mt-1">
              <b-form-input trim
                type="text"
                v-model="payload.name"
                name="payload.name"
              ></b-form-input>
            </div>

            
            <h5 class="mt-3">{{ $t("LICENSE.MULTITENANCY") }}</h5>
            <div class="mt-1">
              <b-form-select
                v-model="payload.multitenancy"
                :options="multitenancy"
              ></b-form-select>
            </div>

            
            <h5 class="mt-3" v-if="license.type == 'PA'">{{ $t("TYPE") }}</h5>
            <div class="mt-1" v-if="license.type == 'PA'">
              <b-form-select
                v-model="payload.type"
                :options="node_type"
              ></b-form-select>
            </div>

            <hr class="p-0 m-0 mt-4 mb-2" />

            <b-button @click="gotoStep1" class="mt-2">{{
              $t("BACK")
            }}</b-button>

            <b-button
              variant="primary"
              class="mt-2 btn-fill"
              :disabled="disabledStep3"
              @click="gotoStep3"
              >{{ $t("NEXT") }}</b-button
            >
          </div>
          <div v-if="step == 3">
            
            <div class="info-header">{{ $t("PRICES_PARTNER") }}</div>
            <PartnerPrices :prices="payload.prices"></PartnerPrices>
            
            <hr class="p-0 m-0 mt-4 mb-2" />

            <b-button @click="gotoStep2">{{ $t("BACK") }}</b-button>
            <b-button @click="gotoStep4">{{ $t("NEXT") }}</b-button>
        
          </div>
          <div v-if="step == 4">

            <div class="info-header">{{ $t("PRICES") }}</div>
            <Prices :prices="payload.prices"></Prices>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button @click="gotoStep2">{{ $t("BACK") }}</b-button>
            
            <b-button
              :disabled="!validEmail"
              variant="primary"
              class="btn-fill"
              @click="createNode"
              >{{ $t("SUBMIT") }}</b-button
            >
          </div>

        </b-overlay>
      </div>
    </b-sidebar>
  </span>
</template>
<script>
import Prices from "@/components/Input/Prices.vue";
import PartnerPrices from "@/components/Input/PartnerPrices.vue";
export default {
  props: ["license"],
  components: { Prices, PartnerPrices },
  data() {
    return {
      step: 1,
      show: false,
      organisations: [],
      organisationRegistered: null,
      loading: false,
      multitenancy: [
        { value: 0, text: this.$t("NO") },
        { value: 1, text: this.$t("YES") },
      ],
      node_type: [
        { value: "EC", text: this.$t("LICENSE.EC") },
        { value: "RS", text: this.$t("LICENSE.RS") },
      ],
      payload: {
        name: "",
        max_accounts: 0,
        organisation_id: "0",
        organisation: {
          name: "",
          unique_identifier: "",
          language: "sv"
        },
        multitenancy: 0,
        type: "EC",
        prices: {
          organisation_id: 0,
          currency: "SEK",
          license: 0,
          accounts: 0,
          n1: 0,
          n2: 0,
          n3: 0,
          n4: 0,
          n5: 0,
          service: 0,
          login_bankid_se: 0,
          login_freja: 0,
          login_freja_org: 0,
          verify_bankid_se: 0,
          verify_freja: 0,
          verify_sms: 0,
          sendgrid: 0,
          description: "",
        },
      }
    };
  },
  methods: {
    showSidebar(){
      this.getOrganisations();
    },
    async gotoStep3() {
      if(this.payload.type == "RS")
      {
        this.step = 3;
      }else{
        this.step = 4;
      }
    },
    async gotoStep4() {
      this.step = 4;
    },
    async gotoStep1() {
      this.step = 1;
      this.organisationRegistered = null;
    },
    async gotoStep2() {
      let self = this;
      let organisation_id = parseInt(this.payload.organisation_id);
      if (organisation_id != 0) {
        self.step = 2;
        self.organisationRegistered = null;
      } else {
        await this.$http
          .post(
            this.user.hostname + "/organisation/is-registered",
            {
              unique_identifier: this.payload.organisation.unique_identifier,
            }
          )
          .then(function(result) {
            if (result.data == false) {
              self.step = 2;
              self.organisationRegistered = true;
            } else {
              self.organisationRegistered = false;
            }
          })
          .catch(function() {
            self.loading = false;
          });
      }
    },
    createNode: function() {
      let self = this;
      this.loading = true;
      this.payload.organisation_id = parseInt(
        this.payload.organisation_id
      );
      this.payload.multitenancy = parseInt(this.payload.multitenancy);
      this.$http
        .post(this.user.hostname + "/node/add", this.payload)
        .then(function() {
          self.show = false;
          self.loading = false;
          self.$emit("addedNode");
          self.$noty.info(self.$t("CREATED"));
        })
        .catch(function() {
          self.loading = false;
        });
    },
    getOrganisations: function() {
      let self = this;
      this.$http
        .post(
          this.user.hostname + "/administration/organisations/list",
          {
            page: this.currentPage,
            limit: 200,
            state: 1,
            invited: 0,
            type: 'node',
            search_text: "",
          }
        )
        .then((response) => {
          self.organisations = response.data.items;
        })
        .catch(() => {
        });
    },
  },
  computed: {
    disabledStep2: function() {
      if (this.payload.organisation_id != "0") {
        return false;
      } else {
        if (
          this.payload.organisation.name != "" &&
          this.payload.organisation.unique_identifier != ""
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    disabledStep3: function() {
      if (this.payload.name != "") {
        return false;
      }
      return true;
    },
    validEmail: function() {
      if (this.payload.name == "") {
        return false;
      }
      return true;
    },
  },
  mounted() {
  },
};
</script>
<style></style>
